import axios from 'axios';
export default {
  async show() {
    return new Promise((resolve, reject) => {
      axios
        .get('/ez_about_us')
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async update(data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/ez_about_us`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
