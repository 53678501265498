<template>
  <WsMain v-if="isMounted">
    <WsState
      type="editor"
      v-model="state.content"
    ></WsState>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          class="ml-8"
          :loading="loading.submit"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
import S_API_Terms from "@/__vue2stone_cms/service/api/v1/ez_about_us";
export default {
  data() {
    return {
      isMounted: false,
      state: {
        content: "",
      },
      loading: {
        submit: false,
      },
    };
  },
  methods: {
    async $_onSubmit() {
      this.loading.submit = true;
      const postData = {
        ...this.state,
      };
      postData.content = btoa(
        unescape(encodeURIComponent(JSON.stringify(postData.content)))
      );
      await S_API_Terms.update(postData);
      this.loading.submit = false;
    },
    async $_valueSet() {
      const res = await S_API_Terms.show();
      this.state.content = res.content;
      this.isMounted = true;
    },
  },
  mounted() {
    this.$_valueSet();
  },
};
</script>

<style>
</style>